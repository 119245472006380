.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 99999;
  position: relative;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid var(--blue-color);
  width: 50px;
  height: 50px;
  margin: auto;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.logo-heigh-auto {
  height: auto;
  margin-bottom: 20px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
