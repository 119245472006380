:root {
  --double-extra-small-font: 0.625rem; /*10px*/
  --extra-small-font: 0.75rem; /*12px*/
  --small-font: 0.875rem; /*14px*/

  --base-font: 1rem; /*16px*/
  --base-lg-font: 1.125rem; /*18px*/
  --base-1lg-font: 1.25rem; /*20px*/
  --base-2lg-font: 1.5rem; /*24px*/

  --large-font: 2rem; /*32px*/

  --input-height: 50px;
  --input-bottom-margin: 16px;

  --primary-color: #7c5838;
  --primary-black-color: #2c2a29;
  --black-color: #000000;
  --gray-color: #7f8c8d;
  --white-color: #ffffff;
  --blue-color: #006cf8;
  --default-gray-color: #f4f4f5;

  --box-background: #fff;
  --box-border-radius: 15px;
  --modal-border-radius: 10px;
  --content-left-right-margin: 1.5rem;
  --top-bar-height: 64px;
  --sidebar-collapse-width: 70px;
  --sidebar-expand-width: 250px;
}

.metadata-loader .loader {
  border-top: #f3f3f3;
}

.blue-color {
  color: var(--blue-color) !important;
}

/*** FLEX PROPS ***/
.align-center {
  align-items: center;
}

/*** BUTTONS ***/

.consumer-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.justify-content-around {
  justify-content: space-around;
}

.button-xxxl {
  width: 320px !important;
  height: 70px !important;
  border-radius: 50px !important;
  font-family: "Manrope";
  font-style: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.button-xxl {
  width: 240px !important;
  height: 53px !important;
  border-radius: 50px !important;
  font-family: "Manrope";
  font-style: normal;
  line-height: normal;
}

.button-xxl span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19.12px;
}

.button-md {
  width: 180px !important;
  height: 50px !important;
  border-radius: 25px 25px !important;
  font-family: "Manrope";
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}

.button-md span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19.12px;
}

.button-smd {
  width: 170px !important;
  height: 50px !important;
  border-radius: 25px 25px !important;
  font-family: "Manrope";
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}

.button-smd span {
  font-weight: 600;
  font-size: 14px;
}

.button-sm {
  width: 140px !important;
  height: 50px !important;
  border-radius: 25px 25px !important;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}

.button-sm span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19.12px;
}

.button-xs {
  width: 100px !important;
  height: 40px !important;
  border-radius: 100px !important;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}

.button-xs span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19.12px;
}

.primary-black-btn {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.primary-black-btn span {
  color: var(--white-color);
  font-size: 14px;
  line-height: normal;
}

.primary-outline-btn {
  border-color: var(--black-color) !important;
  color: var(--black-color) !important;
}

.primary-btn {
  background-color: var(--blue-color);
  border-color: var(--blue-color);
}

.ant-btn-default.consumer-btn:not(:disabled):hover {
  color: white !important;
  background-color: var(--blue-color) !important;
  border-color: var(--blue-color) !important;
}

.primary-disabled {
  background-color: #000000 !important;
  border-color: #000000 !important;
  box-shadow: 0px 4px 6px var(--button-box-shadow) !important;
  opacity: 0.6 !important;
  cursor: not-allowed !important;
  color: #fff !important;
}

.outline-button-icon {
  width: 16px;
  height: 16px;
}

.outline-button-icon img {
  width: 100%;
  height: 100%;
}

.consumer-btn .primary-pre-svgicon {
  margin-right: 10px;
  display: flex;
}
