.Toastify__toast {
  box-shadow: 0 0 10px #bab4b4 !important;
  margin: unset !important;
  min-height: unset !important;
  padding: 0.5rem 0.6rem !important;
  font-size: 0.8rem !important;
  text-align: left !important;
  box-shadow: 0 4px 8px 0 #0000000a;
}

.Toastify__progress-bar {
  background: #fff !important;
  height: 2px !important;
}

.Toastify__close-button--light {
  color: #fff !important;
  opacity: 1 !important;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  position: absolute;
}

.Toastify__toast-icon svg {
  fill: #fff !important;
}

.Toastify__toast .pro .Toastify__toast-container--top-right {
  right: 0.8rem !important;
}

.error-toast-style {
  background: #e74c3c !important;
  color: white !important;
}

.success-toast-style {
  background: #47b14b !important;
  color: white !important;
}

.info-toast-style {
  background: #00ff00 !important;
  color: white !important;
}
