.session-expiry-modal-title {
  color: var(--primary-black-color);
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 3px;
  text-align: center;
}

.session-expiry-modal-description {
  color: var(--primary-black-color);
  text-align: center;
  font-family: "Manrope";
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
  margin-bottom: 33px;
}

.session-expiry-modal-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.session-expiry-modal-timer {
  text-align: center;
  font-size: 20px;
  margin: 16px 0 20px;
}

@media screen and (min-width: 576px) {
  .session-expiry-modal-title {
    font-size: 32px;
  }

  .session-expiry-modal-description {
    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .session-expiry-modal-title {
    font-size: 24px;
    margin: 20px 0;
  }

  .session-expiry-modal-description {
    font-size: 12px;
  }

  .session-expiry-modal-btn-container .session-expiry-btn-container {
    flex-flow: column;
    gap: 1rem;
  }

  .session-expiry-btn-container button:first-child {
    order: 2;
  }

  .session-expiry-btn-container button:nth-child(2) {
    order: 1;
  }
}
