.custom-ant-modal .ant-modal-content {
  border-radius: 15px;
  box-shadow: 0px 15px 100px 0px rgba(0, 0, 0, 0.25);
}

.ant-modal.custom-ant-modal .ant-modal-close-x {
  color: var(--black-color);
  opacity: 0.2515;
}

@media screen and (min-width: 992px) {
  .custom-ant-modal .ant-modal-content {
    padding: 60px 24px;
  }
}

@media screen and (max-width: 991px) and (min-width: 576px) {
  .custom-ant-modal .ant-modal-content {
    padding: 40px 24px;
  }
}

@media screen and (min-width: 576px) {
  .ant-modal.custom-ant-modal .ant-modal-close-x {
    font-size: 24px;
  }

  .ant-modal.custom-ant-modal .ant-modal-close-x svg {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 575px) {
  .custom-ant-modal .ant-modal-content {
    padding: 30px 24px;
  }
}
