/*Tailwind like css*/

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.vertical-bottom {
  vertical-align: bottom;
}

.lh-1-rem {
  line-height: 1.2rem !important;
}

.gap-2 {
  gap: 1.25rem !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-2rem {
  gap: 2rem !important;
}

.sfProHeavy {
  font-family: "sfHeavy";
}

.manrope-semi-bold {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.align-center {
  align-items: center !important;
}

.mt10 {
  margin-top: -10px;
}

.pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
  opacity: 0.3;
}

.box-border-radius {
  border-radius: var(--box-border-radius);
}

.layout-buttons button,
.button-height {
  height: 2.3rem !important;
}

.layout-buttons button,
.layout-button-width {
  width: 9.5rem !important;
}

.layout-button-small-width {
  width: 7rem !important;
}

.layout-button-small-height {
  height: 2.3rem !important;
}

.custom-ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  border: none;
}

.custom-placeholder .ant-select-selection-placeholder,
.custom-placeholder input::-webkit-input-placeholder {
  color: "#006CF8" !important;
}

.custom-ant-pagination .ant-pagination-item {
  vertical-align: unset !important;
}

/* Padding Shorthands*/

.custom-ant-pagination .ant-pagination-item {
  border: none;
}

.custom-ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.custom-ant-pagination .ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 0;
}

.custom-ant-pagination .ant-pagination-item {
  margin-right: 0;
}

.custom-ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.resend-opt {
  cursor: pointer;
}

.resend-opt:hover {
  text-decoration: underline;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-0 {
  margin-left: unset !important;
}

.pl-10 {
  padding-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-0 {
  margin-right: unset !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 1.5rem !important;
}

.pl-15 {
  padding-left: 15px;
}

.pl-half {
  padding-left: 0.5rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.pr-1 {
  padding-right: 1.5rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pr-5 {
  padding-right: 5rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.pr-10 {
  padding-right: 10px;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.px-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mt-5 {
  margin-top: 5rem;
}

.m-auto {
  margin: auto !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-half {
  margin-top: 1.5rem;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-2 {
  margin-bottom: 1.125rem !important;
}

.ml-30 {
  margin-left: 27rem !important;
}

.break-word {
  word-break: break-all;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.text-40px {
  font-size: 40px !important;
}

.text-2lg {
  font-size: 1.938rem !important;
  /*31px*/
}

.text-lg {
  font-size: 1.375rem !important;
  /*22px*/
}

.text-3lg {
  font-size: 2.135rem !important;
}

.text-20px {
  font-size: 20px !important;
}

.text-md {
  font-size: 1.125rem !important;
  /* 18px */
}

.text-base {
  font-size: 1rem !important;
  /*16px*/
}

.text-sm {
  font-size: 0.875rem !important;
  /*14px*/
}

.text-xs {
  font-size: 0.75rem !important;
  /*12px*/
}

.text-2xs {
  font-size: 0.6rem !important;
}

.bold {
  font-weight: 600;
}

.weight-600 {
  font-weight: 600 !important;
}

.weight-500 {
  font-weight: 500 !important;
}

.weight-530 {
  font-weight: 530 !important;
}

.weight-500 {
  font-weight: 500 !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.p-0 {
  padding: 0 !important;
}

.cursor-default {
  cursor: default !important;
}

.direction-row-reverse {
  flex-direction: row-reverse !important;
}

.custom-placeholder .ant-select-selection-placeholder,
.custom-placeholder input::-webkit-input-placeholder {
  color: #34495e !important;
}

.custom-ant-table .ant-table-thead > tr > th {
  background-color: unset !important;
  font-weight: 600;
}

.custom-ant-table
  :is(
    .ant-table-container table > thead > tr:first-child th:first-child,
    .ant-table-tbody > tr > td:first-child
  ) {
  padding-left: 0;
}

.custom-ant-table thead th::before {
  content: unset !important;
}

.ant-table table {
  font-size: 14px;
}

.custom-ant-pagination .ant-pagination-item-active {
  border: unset;
  color: #006cf8;
}

.custom-loader {
  padding: 2rem;
  height: unset !important;
  color: #000;
}

.width-3rem {
  width: 3.5rem;
}

.min-width-fit-content {
  min-width: fit-content;
}

.min-width-8 {
  min-width: 8rem;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

/*Tailwind like css*/
.Helvetica {
  font-family: "Helvetica", sans-serif;
}

.SFProText {
  font-family: "SfProTextRegular" !important;
}

.Manrope {
  font-family: "Manrope", sans-serif;
  font-weight: 400 !important;
}

* {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.br-15 {
  border-radius: 15px !important;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
  overflow-x: hidden;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-end {
  justify-content: end !important;
}

.justify-self-end {
  justify-self: end !important;
}

.justify-start {
  justify-content: start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-start {
  justify-content: flex-start;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-evenly {
  justify-content: space-evenly !important;
}

.mr-79 {
  margin-right: 79px;
}

.mb-31 {
  margin-bottom: 31px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-10 {
  margin-right: 5px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ptb-6 {
  padding: 6px 0;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.mb-76 {
  margin-bottom: 76px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-26 {
  margin-bottom: 26px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-130 {
  margin-right: 130px;
}

.mb-74 {
  margin-bottom: 74px;
}

.mb-100 {
  margin-bottom: 100px;
}

.col50 {
  width: calc(50% - 12px);
}

/**********  Button Style Start  **********/

.button-style {
  display: flex;
  align-items: center;
}

.error-text {
  color: #ff4d4f;
  font-size: 13px;
  /* line-height: 1.5; */
  margin-top: 0px;
}

.button-style .ant-btn span {
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
}

.button-style .ant-btn-primary {
  padding: 0px 80px;
  height: 50px;
  width: 200px;
  border-radius: 25px;
  color: #ffffff;
}

.edit-modal-buttons .button-style {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.edit-modal-buttons .button-style .ant-btn span {
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
}

.edit-modal-buttons .button-style .ant-btn-primary {
  padding: 0px 45px;
  height: 40px;
  border-radius: 5px;
  background-color: #006cf8;
  color: #ffffff;
  border: none;
}

.button-style .ant-btn-primary:active,
.button-style .ant-btn-primary:hover,
.button-style .ant-btn-primary:focus {
  border-color: #1665d8;
  background-color: #1665d8;
  color: #fff;
}

.edit-modal-buttons .button-style .ant-btn-primary:active,
.button-style .ant-btn-primary:hover,
.button-style .ant-btn-primary:focus {
  border-color: #006cf8;
  background-color: #006cf8;
  color: #fff;
}

.button-style .ant-btn-link {
  padding: 0;
  height: 50px;
  border: 1px solid #006cf8;
  color: #006cf8;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.35);
  padding: 0px 80px;
}

.edit-modal-buttons .button-style .ant-btn-link {
  padding: 0px 45px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #006cf8;
  color: #006cf8;
  background-color: rgba(255, 255, 255, 0.35);
}

.button-style .ant-btn {
  margin-right: 30px;
}

.button-style .ant-btn:last-child {
  margin-right: 0px;
}

.edit-modal-buttons .button-style .ant-btn {
  margin-right: 30px;
}

.edit-modal-buttons .button-style .ant-btn:last-child {
  margin-right: 0px;
}

/**********  Button Style End  **********/

/**********  Auth Pages Css Start  **********/

.auth-page {
  width: 100%;
  float: left;
  /* height: 100vh; */
}

.main-wrap {
  display: flex;
  width: 100%;
  float: left;
  height: calc(100vh - 61px);
}

.auth-bg {
  background: url("/public/assets/images/login-bg-image.png") no-repeat;
  background-size: cover;
  width: 100%;
}

.auth-bg-text {
  align-items: end;
}

.auth-form-sec {
  width: 465px;
  float: left;
}

.auth-form-sec {
  padding: 0 40px;
}

.auth-form-container {
  position: relative;
  height: 100%;
}

.auth-form-container .logo {
  margin: 0 0 54px;
}

.auth-text {
  margin-bottom: 100px;
}

.auth-text h2 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -0.94px;
  line-height: 38px;
  margin-bottom: 10px;
}

.auth-text p {
  color: #76828e;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.47px;
  line-height: 19px;
}

.forgot-btn {
  margin: 0px;
}

.forgot-btn .ant-btn-link {
  color: #56575a;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.05px;
  line-height: 20px;
  padding: 0;
}

.forgot-btn .ant-btn-link span {
  text-decoration: underline;
}

.auth-copy-right {
  position: absolute;
  left: 0;
  bottom: 27px;
  width: 100%;
}

.auth-copy-right p {
  color: #9ea0a5;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.05px;
  line-height: 20px;
  margin: 0;
}

.two-factor-sec .auth-form .react-code-input {
  min-width: 100%;
  display: flex !important;
}

.two-factor-sec .auth-form .react-code-input input {
  width: 40px !important;
  margin: 9px !important;
  height: 50px !important;
  color: #212529 !important;
  font-family: "Manrope", sans-serif;
  font-size: 31.2px !important;
  font-weight: 400 !important;
  text-align: center;
  padding: 0 !important;
  border: 1px solid #1665d8 !important;
  border-radius: 10px !important;
}

.two-factor-sec .auth-form .react-code-input input:first-child {
  margin-left: 0px !important;
}

.two-factor-sec .auth-form .react-code-input input:nth-child(3) {
  margin-right: 2rem !important;
}

.two-factor-sec .auth-form .react-code-input input:last-child {
  margin-right: 0px !important;
}

.two-factor-sec .auth-form {
  margin-bottom: 0px;
}

.two-factor-sec .auth-form {
  margin-bottom: 100px;
}

.two-factor-auth .auth-text {
  margin-bottom: 30px;
}

.two-factor-auth .auth-form .ant-btn-primary {
  margin: 0px;
}

.verify-icon {
  margin-bottom: 30px;
}

.verify-icon img {
  max-width: 80px;
}

.authenticator-list {
  max-width: 372px;
  margin: 0 auto;
}

.authenticator-list ul {
  text-align: left;
  padding: 0;
  list-style: decimal;
  margin-bottom: 30px;
}

.authenticator-list ul li {
  color: #3a3b3f;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.05px;
  line-height: 20px;
  margin-bottom: 15px;
}

.authenticator-list ul li img {
  display: block;
  margin: auto;
  margin-top: 12px;
}

.authenticator-app .logo {
  margin: 55px 0 55px;
}

.authenticator-app .auth-text h2 {
  margin-bottom: 8px;
}

.authenticator-app .auth-form {
  margin-bottom: 60px;
}

/**********  Auth Pages Css End  **********/

/**********  Login Page Css Start   **********/

.form-style .ant-form-item .ant-form-item-control .ant-input {
  border-bottom: 1px solid #dbe0e1;
  border-radius: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 7px;
  width: 100%;
}

.form-style .ant-form-item .ant-form-item-control .ant-input::placeholder {
  color: #747f8b;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.form-style .ant-form-item .ant-form-item-control .ant-input-group-addon {
  padding: 0;
  border: none;
  border-bottom: 1px solid #dbe0e1;
  padding-bottom: 3px;
  background: #fff;
}

.form-style
  .ant-form-item
  .ant-form-item-control
  .ant-input-group-addon
  .country-flag
  .ant-select-arrow {
  display: none;
}

.form-style
  .ant-form-item
  .ant-form-item-control
  .ant-input-group-addon
  .country-flag
  .ant-select-selector
  .ant-select-selection-item {
  padding-right: 5px;
  display: flex;
  align-items: center;
  color: #747f8b;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.form-style
  .ant-form-item
  .ant-form-item-control
  .ant-input-group-addon
  .country-flag
  .ant-select-selector
  .ant-select-selection-item
  img {
  border-radius: 3px;
  margin-right: 5px;
}

.form-style .ant-form-item .ant-form-item-control .ant-input-affix-wrapper {
  border: none;
  padding: 0;
  position: relative;
}

.form-style
  .ant-form-item
  .ant-form-item-control
  .ant-input-affix-wrapper
  .ant-input-suffix {
  right: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
}

.form-style
  .ant-form-item
  .ant-form-item-control
  .ant-input-affix-wrapper:focus,
.form-style
  .ant-form-item
  .ant-form-item-control
  .ant-input-affix-wrapper-focused,
.form-style .ant-form-item .ant-form-item-control .ant-picker-focused {
  box-shadow: none;
}

.form-style .ant-form-item .ant-form-item-control .ant-picker {
  border-bottom: 1px solid #dbe0e1 !important;
  border-radius: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 7px;
  border: none;
  width: 100%;
}

/* .form-style .ant-form-item {
  margin-bottom: 14px;
} */

.form-style
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-vertical.form-style .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 0px;
}

.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  color: #747f8b;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.17px;
  line-height: 17px;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #2c2a29;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background-color: #eecea859;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background-color: #2c2a29;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #eecea859;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before {
  background-color: #eecea859;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #2c2a29;
}

.ant-picker-range .ant-picker-active-bar {
  background: #7c583880;
}

.ant-picker-cell-range-hover::before {
  background: transparent;
}

.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #7c583880;
}

.login-text {
  margin: 0 135px 0 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 61px);
}

.login-text .login-page-heading {
  color: #2c2a29;
  font-size: 37px;
  font-weight: 700;
  margin-top: 55px;
  font-family: "Manrope", sans-serif;
}

.Login-spin-height .ant-spin {
  height: 100vh !important;
  max-height: 100vh !important;
}

/* .ant-spin {
  color: #7c5838 !important;
} 

.ant-spin-dot-item {
  background-color: #7c5838 !important;
} */

.login-text .apps-icon-group {
  padding-right: 80px;
  gap: 16px;
}

.login-get-started-btn {
  cursor: pointer;
  border: unset;
  min-height: 50px;
  min-width: 200px;
  border-radius: 25px;
  font-size: 14px;
  background: #1b1b1b;
  color: #fff;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}

.login-form .ant-select .ant-select-arrow svg {
  display: none;
}

.login-form .ant-select .ant-select-arrow > span {
  background-image: url("/public/assets/images/phone-dropdown.png");
  background-repeat: no-repeat;
  background-position: 97% 4%;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.have-acc-sec p {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 20px;
  margin-bottom: 19px;
}

.have-acc-sec .ant-btn-link {
  color: #006cf8;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: -0.5px;
  line-height: 20px;
  padding: 0;
}

.have-acc-sec .ant-btn-link:first-child span {
  border-right: 1px solid #006cf8;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
}

.forgot-btns {
  font-family: "Manrope", sans-serif !important;
  font-weight: 300 !important;
}

.login-page-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 45px 0 0;
}

.login-page-buttons-container .button-style button {
  width: 180px;
  padding: 13px 24px;
}

.login-page-buttons-container .button-style .ant-btn span {
  font-weight: 600;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
}

.login-page-buttons-container .button-style {
  margin-top: 28px;
}

.login-form .form-style .ant-row.mb-100 {
  margin-bottom: 60px;
}

.ath-page-two .auth-bg {
  background-image: url("./statics/images/cloudcard-bg.png");
  max-width: 620px;
}

.ath-page-two .auth-form-sec {
  width: 100%;
  padding: 100px 100px 51px;
  position: relative;
  height: 100vh;
  /* display: flex;
  align-items: center; */
  overflow-y: unset;
}

.ath-page-two .auth-form-container {
  width: 600px;
  position: relative;
  height: calc(100% - 122px);
  display: flex;
  align-items: center;
}

.enrollment-bg {
  background: url("/public/assets/images/signup-verification-screen-bg.png")
    no-repeat;
  background-size: cover;
  min-height: 100%;
  width: 600px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enrollment-back {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 5%;
  width: 5%;
  float: left;
  display: flex;
  justify-content: left;
  align-items: left;
}

.min-h-screen {
  min-height: 100vh;
}

.enrollment-bg-text {
  align-items: end;
}

.button-style .next-btn {
  background: linear-gradient(312.63deg, #0288d1 0%, #006cf8 100%);
}

.button-style .next-btn:hover,
.button-style .next-btn:active,
.button-style .next-btn:focus {
  background: linear-gradient(312.63deg, #006cf8 0%, #0288d1 100%);
}

.button-style .previous-btn {
  background: linear-gradient(312.63deg, #fff 0%, #fff 100%);
}

.button-style .previous-btn:hover,
.button-style .previous-btn:active,
.button-style .previous-btn:focus {
  background: linear-gradient(312.63deg, #fff 0%, #0288d1 100%);
}

.copy-right-sec {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.copy-right-sec > div {
  color: #2c2a29;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin: 0;
}

.personal-info .auth-text {
  margin-bottom: 30px;
}

.ath-page-two .review-detail-container {
  height: auto;
}

.review-detail-sec {
  width: 100%;
  max-width: 600px;
}

.privacy-terms-btn .ant-btn-link {
  color: rgba(44, 42, 41);
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  padding: 0;
  height: auto;
}

.privacy-terms-btn .ant-btn-link:first-child {
  padding-right: 0;
  margin-right: 0;
  border: 0;
}

.privacy-terms-btn .buttons-divider {
  color: rgba(44, 42, 41, 0.5);
  padding: 0 3px;
}

.review-detail {
  margin-bottom: 18px;
}

.review-detail h4 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: -0.71px;
  line-height: 29px;
  margin: 10px 0 30px;
}

.review-detail ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.review-detail ul li {
  border-bottom: 1px solid rgba(149, 165, 166, 0.2);
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  padding-bottom: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.review-detail ul li:last-child {
  margin-bottom: 0;
}

.review-detail ul li span {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #747f8b;
  font-size: 14px;
  letter-spacing: 0.17px;
  line-height: 17px;
  min-width: 200px;
}

.agreement-sec .auth-form-container {
  height: calc(100% - 122px);
}

.agreement-sec .auth-form-sec {
  padding: 130px 100px 51px;
}

.agreement-sec .auth-text h2 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 37px;
  font-weight: 500;
  letter-spacing: -1.09px;
  line-height: 44px;
}

.agreement-sec .auth-text p {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.47px;
  line-height: 27px;
}

.cong-page {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 200px;
}

.congration-modal .cong-page {
  padding-bottom: 0;
}

.cong-page .auth-text {
  margin: 0 auto;
  max-width: 996px;
  text-align: center;
}

.cong-modal-button .layout-button-small-height {
  height: 3.3rem !important;
}

.cong-modal-button .layout-button-small-width {
  width: 13rem !important;
}

.cong-page .auth-text h2 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 37px;
  font-weight: 900;
  letter-spacing: -1.09px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 2px;
}

.cong-page .auth-text p {
  color: var(--primary-black-color);
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
}

.cong-page .cong-icon svg {
  max-width: 268px;
  margin-bottom: 68px;
  fill: white;
}

.cong-image {
  max-width: 268px;
  margin: 0 auto 68px;
}

.cong-image img {
  width: 100%;
}

.cong-page .imp-note {
  margin-bottom: 61px;
}

.cong-page .imp-note h4 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.65px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 16px;
}

.cong-page .imp-note p {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 27px;
  text-align: center;
  margin: 0;
}

.cong-page .button-style {
  justify-content: center;
}

.cong-page .copy-right-sec {
  margin: 0;
  display: block;
  text-align: center;
}

.cong-page .copy-right-sec p {
  margin-bottom: 12px;
}

/**********  Login Page Css End  **********/

/**********  Dashboard Css Start  **********/

.dashboard-container .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.dashboard-container .trigger:hover {
  color: #1890ff;
}

.dashboard-container .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.dashboard-container
  .ant-layout-content.site-layout-background::-webkit-scrollbar-track:hover {
  visibility: visible;
}

.dashboard-container
  .ant-layout-content.site-layout-background::-webkit-scrollbar {
  width: 10px;
}

.dashboard-container
  .ant-layout-content.site-layout-background::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(122, 153, 217, 0.3)),
    color-stop(0.72, rgba(73, 125, 189, 0.3)),
    color-stop(0.86, rgba(28, 58, 148, 0.3))
  );
  opacity: 0;
}

.custom-ant-pagination .ant-table-pagination.ant-pagination {
  margin: 24px 0;
}

.custom-ant-pagination .ant-table-pagination-right {
  justify-content: center;
}

.logout-dropdown .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 20px 35px;
}

.logout-dropdown .ant-dropdown-menu {
  border-radius: 10px;
}

.sidebar-menu-btn {
  padding: 0px 25px 0;
  cursor: pointer;
  margin-bottom: 35px;
}

.sidebar-menu-btn svg {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.side-bar-sec
  .ant-menu-inline.ant-menu-root
  .ant-menu-item
  > .ant-menu-title-content,
.side-bar-sec
  .ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  color: #c5cee7;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.41px;
  line-height: 48px;
  margin-left: 16px;
}

.side-bar-sec .ant-menu-submenu-expand-icon,
.side-bar-sec .ant-menu-submenu-arrow,
.side-bar-sec
  .ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow {
  color: #fff;
}

.side-bar-sec .ant-menu-inline.ant-menu-root .ant-menu-item svg g,
.side-bar-sec .ant-menu-submenu-title svg g {
  fill: #ffffff;
  opacity: 0.8;
}

.side-bar-sec .ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.side-bar-sec
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-title-content {
  color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-weight: 500 !important;
}

.side-bar-sec .ant-menu-submenu-selected .ant-menu-submenu-title svg g {
  fill: url(#linearGradient-1);
  opacity: 1;
}

.side-bar-sec
  .ant-menu-inline.ant-menu-root
  .ant-menu-item-selected
  .ant-menu-title-content {
  color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
}
.ant-menu-item-selected .ant-menu-title-content {
  color: #d1aa67 !important;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
}

.side-bar-sec .ant-menu-inline.ant-menu-root .ant-menu-item-selected svg g {
  opacity: 1;
}

.side-bar-sec .ant-menu.ant-menu-inline-collapsed > .ant-menu-item svg g {
  fill: #010101;
  opacity: 0.8;
}

.side-bar-sec
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  svg
  g {
  opacity: 1;
}

.side-bar-sec .ant-menu-vertical .ant-menu-item:not(:last-child),
.side-bar-sec .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.side-bar-sec .ant-menu-vertical-right .ant-menu-item:not(:last-child),
.side-bar-sec .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 12px;
}

.drpodown-logout {
  align-items: center;
  display: flex;
}

.drpodown-logout svg {
  fill: #7c5838;
  margin-right: 5px;
}

.side-bar-sec .ant-menu-vertical .ant-menu-item.ant-menu-item-selected svg g {
  fill: #ffffff;
}

.card-item-white {
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
  padding: 22px 40px;
}

.launch-card-overlay-content {
  position: relative;
  aspect-ratio: 1/1;
  width: 100%;
  top: 30px;
  border-radius: 23px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.user-and-add-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-name-sec {
  width: 100%;
  margin-right: 24px;
}

.add-btn-sec {
  width: 100%;
  max-width: 194px;
}

.user-and-add-btn .transactions-section {
  margin-right: 20px;
}

.dashboard-username h2 {
  margin: 0;
  color: #000000;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0.6px;
  line-height: 20px;
}

.add-account-btn {
  text-align: center;
}

.add-account-btn .ant-btn-link {
  color: #006cf8;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
}

.add-account-btn .ant-btn-link:active,
.add-account-btn .ant-btn-link:hover,
.add-account-btn .ant-btn-link:focus {
  color: #442c96;
}

.add-account-btn .ant-btn-link img {
  margin-right: 8px;
}

.fnf-header {
  display: flex;
  column-gap: 30px;
}

.fnf-header .transfer-history-btn {
  height: 50px;
  font-size: 16px;
  background-color: #ffffff;
  border-color: #000000;
  color: #000000;
  padding: 4px 25px;
}

.fnf-header .transfer-history-btn:hover {
  background-color: #006cf8;
  border-color: #006cf8;
  color: #fff !important;
}

.dashboard-left-sec {
  width: 100%;
  margin-right: 28px;
}

.dashboard-right-sec {
  width: 100%;
  max-width: 370px;
}

.dashboard-right-sec .cpy-checking {
  padding: 4px 0;
}

.position-relative {
  position: relative;
}

.select-date {
  margin-bottom: 50px;
}

.select-date .ant-picker-range {
  height: 2.5rem;
  border-radius: var(--box-border-radius);
  width: 100%;
}

.select-date .ant-picker-range input::placeholder {
  color: #2c3e50;
  font-weight: 600;
}

.select-date .ant-picker-suffix {
  font-size: 20px;
  color: #2c3e50;
  margin-left: 0;
}

.select-date .ant-picker-input:placeholder-shown {
  color: red;
}

.last-credit .total-balance span {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.32px;
  line-height: 12px;
  text-align: center;
}

.last-credit {
  border-right: 1px solid #bdc3c7;
  padding-right: 20px;
}

.last-debit {
  padding-left: 20px;
}

.last-debit .total-balance span {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.32px;
  line-height: 12px;
  text-align: center;
}

.total-balance span {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.32px;
  line-height: 12px;
  text-align: center;
}

.last-credit .total-balance h2 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.65px;
  line-height: 22px;
  margin-bottom: 4px;
}

.last-debit .total-balance h2 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.65px;
  line-height: 22px;
  margin-bottom: 4px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-10 {
  margin-bottom: 10px;
}

.total-balance h2 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.94px;
  line-height: 35px;
  margin-bottom: 20px;
}

.victory-section {
  display: flex;
  justify-content: center;
}

.total-balance {
  text-align: center;
}

.select-date .ant-picker .ant-picker-range-separator span {
  display: none;
}

.wallets-tabs .account-info .transactions-list {
  width: auto !important;
}

.ant-modal-header {
  display: none;
}

.ant-modal-footer {
  display: none;
}

.edit-acount-modal {
  margin: 0 50px;
  margin-top: 50px;
}

.add-new-modal .edit-acount-modal {
  margin: 0 30px;
  margin-top: 50px;
}

.list-transactions-edit-modal .transactions-list {
  border-bottom: none;
}

.list-transactions-edit-modal {
  background-color: #eef1f5;
  height: 100px;
  display: flex;
  justify-content: center;
}

.edit-modal-input p {
  color: #747f8b;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 16px;
  margin-bottom: 5px;
}

.edit-acount-modal h2 {
  text-align: center;
  margin-bottom: 35px;
}

.tab-header-action .add-account-btn .ant-btn-link {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.11px;
  display: flex;
  align-items: center;
}

.tab-header-action .add-account-btn .ant-btn-link .anticon {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 3px;
}

/**********  Dashboard Css End  **********/

/**********  Dashboard Header Css Start  **********/

.header-title h3 {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 0;
}

.debit-data {
  text-align: center;
}

.debit-data h1 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.94px;
  line-height: 35px;
  margin-bottom: 90px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.debit-data .ant-badge-status-dot {
  width: 15px;
  height: 15px;
}

.drop-down-icon {
  position: absolute;
  background-color: #006cf8;
  color: white;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  border-radius: 17px;
  left: 55px;
  top: 38px;
}

.avatar-item {
  position: relative;
  padding: 0 12px;
}

.search-icon {
  font-size: 20px;
  color: #7f8c8d;
  margin-right: 28px;
}

.badge-credit-debit {
  display: flex;
  justify-content: space-between;
  width: 60%;
  text-align: center;
  margin: 0 auto 54px auto;
}

.badge-credit-debit .ant-badge-status-dot {
  width: 15px;
  height: 15px;
}

.notification-icon {
  padding: 0 19px;
}

.notification-icon .ant-badge {
  font-size: 20px;
  color: #7f8c8d;
}

.power-icon {
  font-size: 20px;
}

.power-icon .ant-btn-link {
  padding: 0;
  font-size: 20px;
}

.power-icon .anticon-poweroff {
  color: #7f8c8d;
}

.nick-name-input .flex-button {
  justify-content: flex-start !important;
}

/**********  Dashboard Header Css End  **********/

/**********  Dashboard Right Css Start  **********/

.select-card {
  margin-bottom: 18px;
}

.select-card .ant-select {
  width: 100%;
}

.cloud-card {
  margin-bottom: 29px;
}

.cloud-card img {
  width: 100%;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 10px 18px 0 rgb(0 0 0 / 10%);
  border-radius: 25px;
}

.show-secure-data {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
}

.show-secure-data p {
  margin-bottom: 0;
  padding-right: 10px;
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
}

.show-secure-data .anticon-eye-invisible {
  color: #7f8c8d;
  font-size: 16px;
  cursor: pointer;
}

.select-card .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: var(--box-border-radius);
  height: 3rem;
  padding: 0 11px;
}

.full-width.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 14px;
}

.time-out-modal h2 {
  font-weight: 800;
  margin-bottom: 25px;
}

.time-out-modal .counter {
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}

.icon-content-area {
  display: flex;
  align-items: center;
}

.icon-content-area svg {
  font-size: 25px;
  color: #006cf8;
  margin-right: 14px;
}

.select-card
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder,
.select-card
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  color: #747f8b;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.17px;
}

.card-info-title h3 {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.14px;
  line-height: 22px;
  margin-top: 43px;
  margin-bottom: 12px;
}

.card-status-list {
  padding-bottom: 27px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 40px;
}

.card-status-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.card-status-list ul li {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 38px;
  display: flex;
  align-items: center;
}

.card-status-list ul li span {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 38px;
  min-width: 100px;
}

.card-status-list .status-badge span {
  min-width: auto;
}

.card-status-list .ant-badge-status-text {
  margin: 0;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.bank-card {
  background-image: url("./statics/images/cardbg.png");
  width: 100%;
  height: 190px;
  border-radius: 17px;
  padding: 20px;
  display: grid;
  align-items: flex-end;
  position: relative;
}

.bank-card .card-icon {
  position: absolute;
  top: 15px;
  left: 255px;
}

.bank-card .card-icon img {
  width: 40px;
}

.bank-card .account-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.bank-card .card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bank-card .account-number span {
  opacity: 0.8;
  color: #d3dde5;
  font-family: "Helvetica Neue";
  font-size: 7.89px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 10px;
}

.bank-card .card-info .expery-date h4 {
  color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
}

.bank-card .expery-date {
  display: flex;
  align-items: center;
}

.bank-card .account-number h3 {
  color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-size: 22.11px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22.11px;
  margin-bottom: 0;
}

.bank-card .card-info h3 {
  color: #ffffff;
  font-family: "Manrope", sans-serif;
  font-size: 22.11px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22.11px;
}

.bank-card .card-info .expery-date span {
  opacity: 0.8;
  color: #d3dde5;
  font-family: "Helvetica Neue";
  font-size: 4.74px;
  font-weight: 500;
  letter-spacing: 0.19px;
  line-height: 6.32px;
  text-align: center;
  margin-right: 4px;
}

/* Widths and Heights Shorthand*/
.min-h-screen {
  min-height: 100vh;
}

.full-width {
  width: 100% !important;
}

.unset-width {
  width: unset !important;
}

.half-width {
  width: 50%;
}

.width-20 {
  width: 20rem;
}

.height-20 {
  height: 20rem;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-100 {
  width: 100% !important;
}
.h-30 {
  height: 30px;
}

.w-130 {
  width: 130px;
}

.width-70 {
  width: 70%;
}

.width-75 {
  width: 75%;
}

.width-80 {
  width: 80%;
}

.width-90 {
  width: 90%;
}

.width-100 {
  width: 100%;
}

.width-110 {
  width: 110%;
}

.width-120 {
  width: 120%;
}

.width-65 {
  width: 65%;
}

.width-75 {
  width: 75%;
}

.width-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.width-15-rem {
  width: 15rem;
}

.width-12-rem {
  width: 12rem;
}

.img-width {
  width: 1.3rem;
}

.full-height {
  height: 100% !important;
}

.full-min-height-vh {
  min-height: 100vh;
}

.half-height {
  height: 50%;
}

.max-vh-height {
  max-height: 70vh;
}

.overflow-auto {
  overflow-y: auto;
}

.width-lg {
  width: 10rem !important;
}

.width-200 {
  width: 250px;
}

.colored-box-size {
  width: 6.5rem;
}

/**********  Dashboard Right Css End  **********/

/**********  Dashboard recent transaction Css Start  **********/

.recent-transactions-dashboard {
  max-height: 450px;
  overflow: scroll;
  overflow-x: hidden;
}

.recent-transactions-dashboard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #451212;
  border-radius: 10px;
  visibility: hidden;
  padding-left: 20px;
  padding-right: 10px;
}

.recent-transactions-dashboard::-webkit-scrollbar {
  width: 5px;
  /* background-color: #F5F5F5; */
}

.recent-transactions-dashboard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #f5f9ff;
}

.recent-transactions-dashboard .recent-title h3 {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.14px;
  line-height: 22px;
  margin-bottom: 17px;
}

.recent-activity-dashboard .recent-activity-title h3 {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.14px;
  line-height: 22px;
}

.transactions-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 19px;
  border-bottom: 1px solid #dfe4dc;
  margin-bottom: 19px;
  width: 18%;
}

.list-transactions-edit-modal .transactions-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 19px;
  border-bottom: 1px solid #dfe4dc;
  margin-bottom: 19px;
  width: 177px;
}

.transactions-list .transaction-list-info h3 {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.47px;
  line-height: 17px;
  margin-bottom: 4px;
  margin-top: 5px;
}

.transactions-list .transaction-list-info p {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 13px;
  margin-bottom: 0;
}

.transactions-list .person-icon {
  align-items: center;
  width: 26px;
  margin-right: 28px;
}

.transactions-list .person-icon svg {
  width: 24px;
}

.collapse-icons {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  justify-content: center;
  margin: 1rem 3.25rem;
  padding: 0px !important;
}

.collapse-icons span {
  font-size: 1.75rem;
}

.ant-dropdown-menu {
  border-radius: 10px;
  padding: 20px 44px;
}

.recent-transactions-dashboard .add-account-btn button {
  color: #0091ff;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.11px;
  line-height: 17px;
}

/**********  Account tabs Css Start  **********/

.account-info .transactions-list .transaction-list-info .account-type li h3 {
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 21px;
  margin-bottom: 5px;
  font-weight: 400;
}

.account-info .transactions-list .transaction-list-info .account-type li p {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 13px;
  margin-bottom: 8px;
}

.account-summary-detail-tabs .account-info .account-detail-eye-image img {
  width: 22px;
  margin-top: 19px;
  margin-left: 17px;
}

.account-info .transactions-list .transaction-list-info .account-type {
  list-style: none;
  margin: 0;
  padding: 0;
}

.account-info .transactions-list {
  border-bottom: none;
  margin-right: 1.2rem;
  margin-bottom: 0;
  padding-bottom: 0;
  min-width: fit-content;
}

.account-info {
  display: flex;
  column-gap: 2.5rem;
}

.edit-acount-modal .account-info .transactions-list {
  width: 33%;
}

.edit-acount-modal .account-info {
  margin-bottom: 26px;
}

.edit-acount-modal
  .account-info
  .transactions-list
  .transaction-list-info
  .account-type
  li
  h3 {
  color: #000000;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.width-fit-content .anticon-eye-invisible {
  margin-left: 5px;
}

/**********  Account tabs transaction Css End  **********/

/**********  wallets Css End  **********/

.wallet-section .transactions-list {
  border-bottom: none;
  width: 29%;
}

.wallet-section .transactions-list {
  margin-bottom: 0;
  padding-bottom: 0;
}

/**********  wallets Css End  **********/

/**********  recent activity Css start  **********/

.cpy-checking {
  display: flex;
  justify-content: space-between;
}

.cpy-checking h3 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.41px;
  line-height: 22px;
  margin-top: 12px;
}

.cpy-checking .placehoder-text h3 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.53px;
  line-height: 20px;
}

.recent-activity-dashboard .ant-select-selector {
  align-items: baseline;
}

.victory-section .VictoryContainer {
  width: 80% !important;
}

.recent-activity-dashboard .recent-acitivty-victory .VictoryContainer text {
  display: none;
}

.victory-status {
  width: 20%;
  margin-top: 51px;
}

.victory-status .status-icons {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.recent-acitivty-victory {
  display: flex;
}

.victory-status .status-icons span {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.41px;
  line-height: 16px;
  text-align: center;
  padding-left: 5px;
}

/**********  recent activity Css End  **********/

/**********  transactions Css start  **********/

.transaction-history-table .table-filters .ant-picker {
  border-radius: 5px;
  font-size: 20px;
  width: 200px;
}

.transaction-history-table .table-filters {
  margin-right: 16px;
}

.transaction-history-table .filter-dropdown {
  margin-right: 16px;
}

.transaction-history-table
  .filter-dropdown
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 5px;
}

.transaction-history-table
  .filter-dropdown
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 6px;
}

.transaction-history-table
  .table-section
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: white;
}

.success-color {
  color: #1abc9c;
}

.transaction-pageheader .ant-page-header {
  padding: 0;
}

.transaction-pageheader .has-breadcrumb {
  padding-top: 0 !important;
}

.shell-gas-section .maps-section img {
  border-radius: 5px 5px 0 0;
  margin-top: -22px;
  margin-left: -29px;
  height: 193px;
}

.shell-gas-section .shell-logo {
  background: white;
  border-radius: 52px;
  width: 94px;
  padding: 20px 0;
  box-shadow: 0px 0px 8px #888888;
  margin-bottom: 20px;
  margin-top: -45px;
}

.clear-filter {
  height: 20px;
  width: 11px;
  color: #e02020;
  /* font-family: "SF Pro"; */
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: 20px;
}

.shell-gas-section .shell-logo img {
  width: 95px;
}

.shell-gas-section .shell-description h1 {
  color: #1d1e1f;
  font-family: "Manrope", sans-serif;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: -0.65px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 4px;
}

.shell-gas-section .shell-description span {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.41px;
  line-height: 16px;
  text-align: center;
}

.shell-gas-section .shell-description {
  margin-bottom: 53px;
}

.shell-gas-section .shell-price h1 {
  color: #1d1e1f;
  font-family: "Helvetica";
  font-size: 40px;
  letter-spacing: -1.18px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
}

.shell-gas-section .shell-price span {
  color: #7f8c8d;
  font-family: "Helvetica";
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 17px;
}

.shell-gas-section .shell-price {
  margin-bottom: 68px;
}

.shell-gas-section .card-status-list ul li {
  justify-content: space-between;
}

.shell-gas-section .card-status-list {
  padding-bottom: 0px;
  border-bottom: none;
  margin-bottom: 30px;
}

.shell-gas-section {
  margin-top: 45px;
}

/**********  transactions Css End  **********/

/**********  Card Operations Css Start  **********/

.card-operation-section .card-operation-info h3 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0px;
}

.card-operation-section .card-operation-info span {
  color: #aaaaaa;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.card-operation-section .ant-collapse-content {
  border-top: none;
}

.card-operation-section .card-operation-form .ant-form {
  margin-left: 40px;
}

.card-operation-section .card-operation-form .ant-form-vertical .ant-form-item {
  margin-right: 45px;
  width: 280px;
}

.card-operation-section .card-operation-form .button-style .ant-btn-link {
  padding: 0px 35px;
  height: 34px;
}

.card-operation-section .card-item-white {
  padding: 48px 0px;
}

.card-operation-section .ant-collapse-item-active {
  background: #ebf1f8;
  padding: 12px 0px;
}

.card-operation-section .card-operation-style {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  padding: 0 30px;
}

.card-operation-section .ant-collapse-item-active .card-operation-info h3 {
  color: #006cf8;
}

/**********  Card Operations Css End  **********/

/**********  My Account Css start  **********/

.my-account-section .my-account-info h1 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.94px;
  line-height: 38px;
  margin-bottom: 13px;
}

.my-account-section .my-account-info span {
  color: #76828e;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.47px;
  line-height: 19px;
}

.my-account-section .my-account-info {
  margin-bottom: 83px;
}

.my-account-section .bank-card {
  width: 313px;
}

.my-account-section .available-balance span {
  color: #34495e;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: -0.5px;
  line-height: 45px;
}

.my-account-section .available-balance h1 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 22px;
}

.my-account-section .available-balance {
  margin-left: 30px;
}

.my-account-section .card-item-white {
  padding: 68px 81px;
}

/**********  My Account Css End  **********/

/**********  Unactivate dashboard Css start  **********/

.unactivate-right-dashboard .bank-card {
  background: #7c808d;
}

.activation-button {
  text-align: center;
}

.activation-button h3 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.59px;
  line-height: 24px;
}

.activation-button .ant-btn {
  height: 40px;
  padding: 8px 35px;
  font-size: 16px;
  border-radius: 7px;
}

.friend-family-header .header-title p {
  margin-bottom: 5px;
}

.add-bank-form .ant-form-item-label > label::after {
  margin: 0 44px 0 2px;
  content: "";
}

.add-bank-form-confirm .form-data {
  display: flex;
  margin-bottom: 30px;
}

.add-bank-form-confirm .form-data p {
  color: #747f8b;
  font-size: 14px;
  letter-spacing: 0.17px;
  line-height: 17px;
  width: 230px;
}

.add-bank-form-confirm .form-data span {
  color: #2c3e50;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.add-bank-form input::placeholder {
  color: #bfbebe;
}

.add-bank-form .ant-input {
  padding: 7px 11px;
  width: 40%;
}

.add-bank-form .ant-form-item .ant-form-item-label {
  width: 185px;
  text-align: left;
}

.add-bank-form .ant-form-item-label > label {
  color: #747f8b;
}

.add-bank-form .ant-checkbox-inner {
  width: 25px;
  height: 25px;
}

.add-bank-form-confirm .ant-checkbox-inner {
  width: 25px;
  height: 25px;
}

.add-bank-form-confirm .ant-checkbox-inner::after {
  left: 29.5%;
}

.add-bank-form-confirm .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.add-bank-form .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.mb-19 {
  margin-bottom: 19px;
}

.pl-22 {
  padding-left: 22px;
}

.add-bank-form .ant-checkbox-inner::after {
  left: 29.5%;
}

.friend-family-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.friend-family-header .right-side-buttons {
  display: flex;
  align-items: center;
}

.friend-family-header .right-side-buttons .anticon-search {
  font-size: 20px;
}

.friend-family-header .right-side-buttons .add-account-btn {
  margin-left: 48px;
}

.unactivate-dasboard-tabs .recent-button {
  background-color: #d8d8d8;
  margin-bottom: 46px;
}

.friend-family-table .delect-icon .anticon-delete {
  font-size: 20px;
}

.initials-friends-and-family {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #006cf81a;
  color: #006cf8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  font-weight: 500;
}

.initials-friends-and-family-title {
  color: #000;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.initials-friends-and-family-add-recipient {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #006cf81a;
  color: #006cf8;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.apply-filter-btn-add-friend {
  height: 50px !important;
  min-height: unset !important;
  width: 200px;
  min-width: unset !important;
  border-radius: 25px !important;
  background-color: var(--primary-black-color) !important;
  border-color: var(--primary-black-color) !important;
}

.apply-filter-btn-add-friend:hover {
  color: white !important;
  background-color: #006cf8 !important;
  border-color: #006cf8 !important;
}

.bank-transfer-table .friend-family-table .delect-icon span {
  width: 50px;
  float: left;
}

.friend-family-table .delect-icon span {
  width: 50px;
  float: right;
}

.avatar-alignment .ant-card-meta {
  align-items: center;
}

.friend-family-table .ant-table-thead > tr > th {
  background: transparent;
  border-bottom: 1px solid #bdc3c7;
  /* padding: 8px 40px; */
  padding-left: 0px !important;
}

/* .friend-family-table .ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-tbody>tr>th, .ant-table-wrapper .ant-table-tbody>tr>td, .ant-table-wrapper tfoot>tr>th, .ant-table-wrapper tfoot>tr>td {
  position: relative;
  padding: 16px 0px;
  overflow-wrap: break-word;
  border-bottom: 1px solid rgba(189, 195, 199, 0.5) !important;
} */

.friend-family-table
  .bank-transfer-table
  .friend-family-table
  .ant-table-thead
  tr
  th {
  width: 35%;
}

.bank-transfer-table .friend-family-table .delect-icon span {
  margin-top: 5px;
}

/* .bank-transfer-table .friend-family-table .ant-table-thead > tr > th,
.bank-transfer-table .friend-family-table .ant-table-tbody > tr > td,
.bank-transfer-table .friend-family-table .ant-table tfoot > tr > th,
.bank-transfer-table .friend-family-table .ant-table tfoot > tr > td {
  padding: 16px 40px !important;
} */

.friend-family-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}

.friend-family-table .layout-button-small-width {
  width: 10rem !important;
}

.friend-family-table .custom-button {
  display: block;
  background-color: transparent !important;
  border-color: #000 !important;
  color: #000 !important;
  outline: none !important;
}

.friend-family-table .custom-button:hover {
  background-color: #006cf8 !important;
  border-color: #006cf8 !important;
  color: white !important;
}

.bank-transfer-table .friend-family-table .custom-button {
  display: block;
}

.friend-family-table .delect-icon span svg {
  display: block;
}

.w-160 {
  width: 160px;
}

.float-right {
  float: right;
}

.h-36 {
  height: 36px;
}

.friend-family-table .ant-table-row:hover .delect-icon svg {
  display: block;
  cursor: pointer;
  float: right;
}

.friend-family-table .ant-table-row:hover .verified {
  display: none;
}

.friend-family-table .ant-table-row:hover .in-progress {
  display: none;
}

.friend-family-table .ant-table-row:hover .un-verified {
  display: none;
}

.bank-transfer-btn {
  border: 1px solid;
  border-radius: 15px;
  padding: 10px 1rem;
  margin: 6px auto;
  align-self: center;
  cursor: pointer;
  min-height: 2rem;
  min-width: 150px;
}

.send-receive-button {
  display: none;
}

.verify-send-button {
  display: none;
}

.friend-family-table .ant-table-row:hover .verify-send-button {
  display: block;
  cursor: pointer;
}

.friend-family-table .ant-table-row:hover .send-receive-button {
  display: block;
  cursor: pointer;
}

.bank-transfer-table .ant-table-row:hover .ant-table-cell:last-child {
  padding-right: 26px !important;
}

.bank-transfer-table .ant-table-row:hover {
  height: 69px;
}

.friend-family-table .ant-table-row:hover .custom-button {
  display: block;
  cursor: pointer;
  float: right;
}

.friend-family-table .ant-table-thead > tr > th {
  font-size: 16px;
  color: #000;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.friend-family-table .ant-table-cell .ant-card-meta-title {
  color: #7f8c8d;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.friend-family-table .ant-table-cell .ant-card-meta-title:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #006cf8;
}

/* .friend-family-table {
  margin: 0px -41px;
} */

.custom-ant-pagination .ant-pagination-item a:hover {
  color: #006cf8;
  border-color: #006cf8;
}

.custom-ant-pagination .ant-pagination-item:hover {
  border-color: #006cf8;
}

.custom-ant-pagination .ant-pagination-item-active a {
  color: #006cf8;
}

.custom-ant-pagination .ant-pagination-item-active {
  border-color: #006cf8;
}

.friend-family-header .right-side-buttons .add-account-btn button {
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  font-weight: 900;
}

.friend-family-table .ant-table-tbody > tr > td {
  color: #000;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  width: 370px;
}

.friend-family-table .ant-table-tbody > tr > td {
  padding-left: 0px;
  border-bottom: 1px solid rgba(189, 195, 199, 0.5) !important;
}

/* .friend-family-table .ant-table-tbody > tr > td:last-child {
  padding-right: 40px;
} */

.bank-transfer-table
  .friend-family-table
  .ant-table-tbody
  > tr
  > td:last-child {
  padding-right: 300px !important;
}

.friend-family-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  margin-left: 40px;
  padding-left: 1rem !important;
}

.friend-family-table
  .ant-table-container
  table
  > thead
  > tr:last-child
  th:last-child {
  margin-right: 40px;
}

.add-recipient-section .header-title p {
  margin-bottom: 0px;
  line-height: 26px;
}

.search-consumer {
  display: flex;
  align-items: center;
}

.search-consumer .ant-input {
  width: 200px;
  margin-right: 10px;
}

.search-consumer .PhoneInput input {
  padding: 5px;
  border: 1px solid #dce1e1;
  border-radius: 6px;
  width: 175px;
}

.search-consumer input::placeholder {
  color: #2c3e50;
  font-size: 14px !important;
  opacity: 1;
}

.mr-15 {
  margin-right: 15px;
}

.search-consumer .ant-input {
  padding: 5px;
  border: 1px solid #dce1e1;
  border-radius: 6px;
}

.search-consumer .PhoneInput input {
  color: #2c3e50;
  font-size: 14px;
}

.search-consumer .PhoneInputCountryIcon {
  font-size: 12px;
}

.search-consumer .PhoneInputCountrySelect {
  color: black;
  font-size: 14px;
}

.search-consumer .PhoneInput input::placeholder {
  color: #2c3e50;
  font-size: 14px !important;
  opacity: 1;
}

.add-recipient-section .add-recipient-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-bank-form input[type="number"] {
  font-size: 14px !important;
}

.opt-user-detail .opt-user-info h4 {
  color: #000000;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-top: 0px;

  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0;
}

.opt-user-detail .opt-user-info .ant-avatar {
  margin: auto;
  margin-bottom: 14px;
}

.opt-user-detail .opt-form {
  width: 60%;
}

.auth-form .react-code-input input {
  width: 40px !important;
  margin: 9px !important;
  height: 50px !important;
  color: #006cf8 !important;
  font-family: "Manrope", sans-serif;
  font-size: 31.2px !important;
  font-weight: 400;
  text-align: center;
  padding: 0 !important;
  border: 1px solid #006cf8 !important;
  border-radius: 10px !important;
}

.auth-form .react-code-input input:first-child {
  margin-left: 0px !important;
}

.auth-form .react-code-input {
  display: flex !important;
  justify-content: center;
}

.auth-form .react-code-input input:last-child {
  margin-right: 0px !important;
}

.auth-form {
  margin: 40px 0px;
}

.opt-user-detail .opt-user-info {
  width: 40%;
  text-align: center;
  background-color: rgba(124, 88, 56, 0.1);
  padding: 20px 0;
  border-radius: 10px;
}

.deposit-modal a {
  text-decoration: underline;
  color: #006cf8;
}

.deposit-modal h2 {
  font-weight: 600;
  color: #006cf8;
}

.opt-user-detail .opt-user-info p {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #7f8c8d;
  font-size: 14px;
  margin-top: 0px;
  letter-spacing: 0;
  margin-bottom: 0;
  line-height: 19px;
  padding: 0 20px;
  display: block;
}

.opt-modal {
  width: 100%;
}

.opt-modal .opt-modal-heading h3 {
  color: #000;
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.94px;
  line-height: 35px;
}

.add-recipient-section .search-recipient-button .layout-button-small-width {
  width: 11rem !important;
}

.add-recipient-section .search-recipient-button .layout-button-small-height {
  height: 2.5rem !important;
}

.recipient-users-list .recipient-users-info > span {
  display: block;
  line-height: 20px;
}

.recipient-users-list .recipient-users-info span {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
}

.recipient-users-list .recipient-users-info p {
  color: #000000;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0;
}

.main-drawer .ant-drawer-content-wrapper {
  width: 500px !important;
}

.mb-32 {
  margin-bottom: 32px;
}

.recipient-list {
  width: 100%;
  display: flex;
}

.recipient-list-style {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0px 16px 0 0;
}

.recipient-list .recipient-list-style .recipient-list-heading h3 {
  color: #2c3e50;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.47px;
  line-height: 17px;
  margin-bottom: 28px;
}

.recipient-users-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 10px;
  width: 100%;
}

.recipient-users-list:hover {
  background: rgba(0, 108, 248, 0.1);
  border-radius: 8px;
}

.recipient-users-list:hover .recipient-users-info span {
  color: #2c2a29 !important;
}

.recipient-users-list:hover .recipient-users-info p {
  color: #000000 !important;
  font-weight: bold;
}

.recipient-users-list .plus-image {
  cursor: pointer;
  padding: 5px;
  color: #006cf8;
}

.recipient-users-list .plus-image :hover {
  color: #03459a;
}

.recipient-users-list-style {
  display: flex;
  align-items: center;
}

.recipient-users-info {
  margin-left: 20px;
}

.bank-transfer-verify .desposit-amounts-title h1 {
  color: #4a4a4a;
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
}

.account-field-label {
  font-size: 12px;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

/* .bank-transfer-verify .verify-button {
  padding: 24px 135px 24px 135px;
} */

.verify-button .ant-row {
  justify-content: center;
}

.bank-transfer-verify .desposit-amounts-title p {
  color: #7f8c8d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.verify-drawer .ant-drawer-body {
  padding: 0;
}

.p-24 {
  padding: 24px;
}

.w-200 {
  width: 200px;
}

.mb-34 {
  margin-bottom: 34px;
}

.bank-transfer-verify .desposit-amounts-title {
  background-color: #eef1f5;
  padding: 60px 24px 26px 24px;
}

.bank-transfer-verify .deposit-amount-input input[type="number"] {
  font-size: 17px !important;
}

.bank-transfer-verify .deposit-amount-input {
  padding: 65px 24px 65px 24px;
}

.bank-transfer-verify .deposit-amount-input p {
  color: #747f8b;
  font-size: 14px;
  letter-spacing: 0.17px;
  line-height: 17px;
  margin-bottom: 15px;
}

.bank-transfer-verify .deposit-amount-input .ant-input-affix-wrapper {
  padding: 7px 11px;
  font-size: 16px;
  width: 435px;
}

.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: rgba(0, 108, 248, 0.07);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: rgba(0, 108, 248, 0.1) !important;
  color: var(--primary-black-color);
}

.activate-card-form .login-form .ant-picker-input {
  width: 280px;
}

.activate-card-form .ath-page-two .auth-form-sec {
  padding: 0;
  overflow-y: hidden;
}

.activate-card-form .ath-page-two .auth-form-container {
  width: 100%;
  justify-content: center;
}

.unactivate-dasboard-tabs .dashboard-account-tabs .ant-collapse-content {
  border-top: none;
}

.card-background {
  color: #ffffff;
  background: linear-gradient(144.45deg, #0647a6 0%, #442c96 100%);
}

.empty-card-background {
  color: #7f8c8d;
  background: #d3d3d385;
}

.anticon-credit-card {
  font-size: 1.5rem;
}

.action-button {
  border: 1px solid rgba(0, 0, 0, 1) !important;
  width: 180px !important;
  height: 50px !important;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.add-beneficiary-list-details {
  color: #2c2a29b3;
}

.action-button.cancel-button {
  color: #000 !important;
  background: #fff;
}

.action-button.next-button {
  color: #fff !important;
  background: #000 !important;
}

.action-button.next-button span {
  font-weight: 600;
}
/**********  Unactivate dashboard Css End  **********/

/**********  Media Query Start  **********/

@media screen and (max-width: 1140px) {
  .dashboard-main-layout {
    display: block;
  }

  .dashboard-left-sec {
    margin: 0 0 24px 0;
  }
}

@media screen and (max-width: 996px) {
  .account-info .transactions-list {
    margin-right: 10px;
    border-right: 1px solid #e7e1e1;
    padding-right: 10px;
  }

  .trans-act-mob {
    display: block;
  }

  .trans-act-mob .col50 {
    width: 100%;
  }

  .trans-act-mob .recent-transactions-dashboard {
    margin-bottom: 24px;
  }

  .dashboard-right-sec {
    width: 100%;
    max-width: 100%;
  }

  .dashboard-account-tabs
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    top: 50%;
    margin-top: -12px;
  }
}

@media screen and (max-width: 768px) {
  .main-wrap {
    display: block;
  }

  .auth-bg {
    height: 170px;
    background-size: cover;
  }

  .auth-bg,
  .auth-form-sec {
    width: 100%;
  }

  .auth-form-container .logo {
    margin: 60px 0 60px;
  }

  .auth-form {
    margin-bottom: 60px;
  }

  .auth-copy-right {
    position: initial;
  }

  .forgot-btn {
    margin: 0 0 40px 0;
  }
}

@media screen and (min-width: 992px) {
  .dashboard-container
    .ant-layout-content.site-layout-background::-webkit-scrollbar-track {
    border-radius: 10px;
    visibility: hidden;
    padding-left: 1.1vw;
  }
}

/**********  Media Query End  **********/

.breadcrumbs-container .ant-breadcrumb-link a {
  color: #2c2a29 !important;
}

.add-friend-btn-friends-and-family {
  height: 50px;
  padding: 0px 32px;
  border-radius: 25px !important;
  color: white !important;
  background-color: #2c2a29 !important;
  border-color: #2c2a29 !important;
  outline: none !important;
}

.add-friend-btn-friends-and-family:hover {
  color: white !important;
  background-color: #006cf8 !important;
  border-color: #006cf8 !important;
}

::placeholder {
  color: rgb(127, 140, 141) !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: rgb(127, 140, 141) !important;
}

.transfer-screen-buttons {
  width: 170px;
  height: 45px !important;
  border-color: #000000;
}

.transfer-screen-buttons:hover {
  background-color: #006cf8;
  border-color: #006cf8;
  color: #fff !important;
}

.transfer-screen-buttons-next {
  width: 170px;
  height: 45px !important;
  background-color: #000;
  color: white;
}

.transfer-screen-buttons-next:hover {
  background-color: #006cf8;
  border-color: #006cf8;
  color: #fff !important;
}

/**** Font Weight Classes ****/

.sf-pro-text-bold {
  font-family: "SfProTextBold" !important;
}

.Manrope-Bold {
  font-family: "Manrope", sans-serif !important;
  font-weight: 700;
}

.sf-pro-text-medium {
  font-family: "SfProTextMedium" !important;
}

.Manrope-Medium {
  font-family: "Manrope", sans-serif !important;
  font-weight: 500;
}

.mat-ripple-element {
  display: none !important;
  background-color: transparent !important;
}

.disabled-cursor {
  cursor: not-allowed !important;
}

.ant-btn-default:not(:disabled):active {
  color: inherit;
  border-color: inherit;
}
.ant-btn:not(:disabled):focus-visible {
  outline: 0;
  outline-offset: unset;
}

.Mui-focusvisible {
  background-color: transparent !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 35px white inset !important;
}

.deposit-container .ant-form-item {
  width: 100%;
}
.deposit-container .ant-select .ant-select-selection-wrap {
  display: flex;
  width: calc(100% - 30px);
  position: relative;
}

@media screen and (max-width: 991px) {
  .full-flex-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Account Statement */
.statement-table .ant-table-thead > tr > th {
  background: transparent !important;
  border-bottom: 2px solid #000000 !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  color: #000000;
  font-family: "Manrope", sans-serif;
}

.statement-table .ant-table-thead > tr > th::before {
  width: 0px !important;
}

.ant-tooltip .ant-tooltip-inner {
  font-size: 12px !important;
}
