.flex-button {
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 350px) {
  .flex-button {
    gap: 1rem;
  }

  .flex-button button:nth-child(2) {
    order: 1;
  }
  .flex-button button:nth-child(1) {
    order: 2;
  }

  .flex-button button {
    width: 120px !important;
  }
  .flex-button .button-xxl {
    width: 100% !important;
  }
}
